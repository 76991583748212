import { DOCUMENT, Location, LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as numeral from 'numeral';
import * as dayjs from 'dayjs';
import { NgbDateMomentParserFormatter } from '../NgbDateMomentParserFormatter';
import { NgDateDEParser } from '../NgDateDEParser';
import { PracticeInfo } from '../PracticeInfo';
import { SelectedValues } from '../SelectedValues';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgDateDEParser },
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new NgbDateMomentParserFormatter('DD.MM.YYYY')
    }
  ]
})
export class Step3Component implements OnInit {
  @Input() selectedValues: SelectedValues;
  @Input() practiceInfos: PracticeInfo;
  @Input() mobileErr: boolean;
  @Input() transComplete: boolean;
  @Input() ongoingSubmit: boolean;
  @Input() appointmentTakenErr: boolean;
  @Input() selectedRoom: any;
  @Input() color;
  @Input() isVAMED?: boolean;
  @Input() physicianPicture?: string;
  @Input() personPicture?: string;

  @Output() nextStep = new EventEmitter<number>();

  profileForm: FormGroup;
  minDate: NgbDateStruct = { year: dayjs().year() - 110, month: 12, day: 31 };
  maxDate: NgbDateStruct = { year: dayjs().year(), month: dayjs().month() + 1, day: dayjs().date() };
  constructor(private fb: FormBuilder, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    this.initForm(this.practiceInfos.practice);
  }

  SvChecker(c: AbstractControl): { invalid: boolean } {
    const dateOfBirth = c.get('dateOfBirth');
    // console.log(dateOfBirth);
    dateOfBirth.setErrors(null);

    let dateJson;

    if (dateOfBirth.value != null) {
      const dateValue = dateOfBirth.value;
      dateJson = moment([dateValue.year, dateValue.month - 1, dateValue.day]).add(4, 'hours');
      if (dateJson.diff(moment()) > 0) {
        dateOfBirth.setErrors({ incorrectGD: false });
      }
      dateJson = dateJson.toDate().toJSON();
    }
    // console.log(dateJson);
    if (!dateOfBirth.value) {
      dateOfBirth.setErrors({ incorrectGD: false });
    }
    const socialSecurity = c.get('socialSecurity');
    socialSecurity.setErrors(null);
    // console.log(c);
    // console.log(validateSVNR(socialSecurity.value, dateJson));

    if (dateOfBirth.valid === true && socialSecurity.valid === true && socialSecurity.value.length) {
      if (!validateSVNR(socialSecurity.value, dateJson)) {
        socialSecurity.setErrors({ incorrectSV: false });
        dateOfBirth.setErrors({ incorrectGD: false });
        return { invalid: true };
      }
    }

    function validateSVNR(insuranceNo: any, checkDateOfBirth: any) {
      const dateNo = moment(checkDateOfBirth).format('DDMMYY');
      // if the entered "insuranceNo" text value has a length of 10, use it for validation, otherwise combine it with the formatted date of birth
      const checkString = insuranceNo.length == 10 ? insuranceNo : insuranceNo + '' + dateNo;

      if (checkString.length != 10) {
        return false;
      }

      const enteredString = numeral(`${checkString}`).format('0000000000');

      if (isNaN(enteredString)) {
        return false;
      }
      let sum = 0;
      sum += parseInt(enteredString.charAt(0), 10) * 3;
      sum += parseInt(enteredString.charAt(1), 10) * 7;
      sum += parseInt(enteredString.charAt(2), 10) * 9;
      sum += parseInt(enteredString.charAt(4), 10) * 5;
      sum += parseInt(enteredString.charAt(5), 10) * 8;
      sum += parseInt(enteredString.charAt(6), 10) * 4;
      sum += parseInt(enteredString.charAt(7), 10) * 2;
      sum += parseInt(enteredString.charAt(8), 10) * 1;
      sum += parseInt(enteredString.charAt(9), 10) * 6;
      const remainder = sum % 11;
      return remainder === parseInt(enteredString.charAt(3), 10);
    }
  }

  initForm(practice) {
    this.profileForm = this.fb.group({
      salutation: ['', practice.EnableGenderValidation ? [Validators.required] : []],
      // firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z]+')]],
      // lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z]+')]],
      firstName: ['', [Validators.required, Validators.pattern('.{1,255}')]],
      lastName: ['', [Validators.required, Validators.pattern('.{1,255}')]],
      title: [''],
      taskNotes: ['', Validators.maxLength(500)],
      SvCheck: this.fb.group(
        {
          dateOfBirth: ['', Validators.required],
          socialSecurity: ['', [Validators.minLength(4), Validators.maxLength(10), Validators.pattern('[0-9]{4,10}')]]
        },
        { validator: this.SvChecker }
      ),
      email: ['', [Validators.required, Validators.email]],
      mobile: [
        '',
        [Validators.required, Validators.minLength(9), Validators.maxLength(20), Validators.pattern('[0-9]+')]
      ],
      // check for VAMED environment (.vamed.com) and form - checkbox fields
      ...(this.isVAMED
        ? {
            vamedCheck1: ['', Validators.requiredTrue],
            vamedCheck2: ['', Validators.requiredTrue],
            vamedCheck3: ['', Validators.requiredTrue]
          }
        : {
            check: ['', Validators.requiredTrue],
            portalTermsCheck: [{ value: '', disabled: true }, Validators.requiredTrue]
          })
    });

    if (practice.CustomTermsURL) {
      this.profileForm?.get('portalTermsCheck')?.enable();
    }
  }

  goTo(step: number): void {
    this.nextStep.emit(step);
  }

  onSubmit() {
    const date = this.profileForm.get('SvCheck').value.dateOfBirth;
    this.selectedValues.Event.GenderID = this.profileForm.value.salutation;
    this.selectedValues.Event.FirstName = this.profileForm.value.firstName.trim();
    this.selectedValues.Event.LastNameEn = this.profileForm.value.lastName.trim();
    this.selectedValues.Event.DateOfBirth = moment([date.year, date.month - 1, date.day])
      .add(4, 'hours')
      .toDate()
      .toJSON();
    this.selectedValues.Event.EMail = this.profileForm.value.email;
    this.selectedValues.Event.InsuranceNo = this.profileForm.get('SvCheck').value.socialSecurity;
    this.selectedValues.Event.PersonTitle = this.profileForm.value.title;
    this.selectedValues.Event.PhoneMobile = this.profileForm.value.mobile;
    this.selectedValues.Event.Description = this.profileForm.value.taskNotes;
    // this.profileForm.reset(this.profileForm.value);
  }

  // onChange(date: any) {
  //      this.profileForm.value = moment([date.year, date.month - 1, date.day]).add(4, 'hours').toDate().toJSON();
  // }
}
